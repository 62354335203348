* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: none;
    /* font-family: 'Gilroy'; */
}

*:focus {
    outline: none;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    background-color: #ffffff;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background-color: #ffffff;
    color: #343340;
    font-weight: 400;
}

body,
html {
    /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
    -webkit-tap-highlight-color: transparent;
}

/* button.slick-arrow.slick-next,
button.slick-arrow.slick-prev {
  display: none !important;
} */

/* input,
label,
textarea {
  font-family: 'Gilroy' !important;
} */

h1,
h2,
h3,
h4,
h5,
h6,
.MuiButtonBase-root,
button {
    font-family: 'Poppins';
}

a {
    color: inherit;
    text-decoration: none;
}

a:focus {
    outline: none;
}

div[role='button'] {
    border: none;
    outline: none;
}

::-webkit-scrollbar {
    /* width: 8px; */
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #d3d7e0;
    border: 0px none #ffffff;
    border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
    background: #a3a5b0;
}
::-webkit-scrollbar-thumb:active {
    background: #8c8e9b;
}
::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 100px;
}
::-webkit-scrollbar-track:hover {
    background: #e4e6eb;
}
::-webkit-scrollbar-track:active {
    background: #e4e6eb;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

@media (hover: none) {
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.Toastify__toast-container {
    padding: 0;
    top: 1.5rem;
}

.Toastify__toast-container--top-center {
    top: 1.5rem;
    right: 0;
    left: auto;
    transform: none;
}

.Toastify__toast {
    border-radius: 8px;
    margin: 0 1.5rem 0;
    padding: 0.5rem;
    box-shadow: none;
    transition: filter 0.3s ease-out;
    /* backdrop-filter: blur(16px); */
}

/* .Toastify__toast:first-of-type {
  margin-top: 1rem;
} */

.Toastify__toast:hover {
    filter: brightness(1.1);
}

@media screen and (min-width: 844px) {
    .Toastify__toast {
        margin: 0 2rem 1rem;
    }
    .Toastify__toast-container {
        width: 384px;
        top: 2rem;
    }
}

.Toastify__toast-body {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 400;
    padding: 1rem;
}

.Toastify__toast--success {
    background: linear-gradient(to right, #07bc0c, #006609);
    box-shadow: 0px 4px 24px #0066094d;
    color: #023103;
    color: white;
}
.Toastify__toast--dark {
    background: linear-gradient(to right, #343340, #343340);
    box-shadow: 0px 4px 24px #3433404d;
    color: #ffffff;
}
.Toastify__toast--default {
    /* background: linear-gradient(to right, #F0F2F5, #D3D7E0); */
    /* color: #343340; */
    background: linear-gradient(to right, #343340, #343340);
    box-shadow: 0px 4px 24px #3433404d;
    color: white;
}
.Toastify__toast--info {
    background: linear-gradient(to right, #3498db, #0a3b5c);
    box-shadow: 0px 4px 24px #0a3b5c4d;
    color: #0a3b5c;
    color: white;
}
.Toastify__toast--warning {
    background: linear-gradient(to right, #f1c40f, #a17600);
    box-shadow: 0px 4px 24px #a176004d;
    color: #604e06;
    color: white;
}
.Toastify__toast--error {
    background: linear-gradient(to right, #e74c3c, #5c120a);
    box-shadow: 0px 4px 24px #5c120a4d;
    color: #5c120a;
    color: white;
}
.Toastify__close-button--default {
    color: white;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* @keyframes pageTransition {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
} */

/* .fade-enter,
.fade-enter.fade-enter-active,
.fade-exit,
.fade-exit.fade-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
} */

.fade-enter,
.fade-enter.fade-enter-active {
    transition: opacity 0.6s ease-in-out, transform 0.3s ease-out;
    /* // 1s 2s */
}

.fade-exit,
.fade-exit.fade-exit-active {
    transition: opacity 0.6s ease-in-out, transform 0.3s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.fade-enter {
    opacity: 0;
    transform: translateX(100vw);
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateX(0px);
}

.fade-exit.fade-enter-exit {
    opacity: 1;
    transform: translateX(0px);
}

.fade-exit {
    opacity: 0;
    transform: translateX(-100vw);
}

/* .fade-enter {
  opacity: 0;
}
.fade-active {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-active {
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
} */
